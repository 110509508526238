export enum Challenges {
  enableVisibilityByName = 'specs.challenges.OOIEnableVisibilityByName',
  enableDripContent = 'specs.challenges.WebMemberDripContent',
  skipMAInstallation = 'specs.programs.OOISkipMAInstallationUsers',
  enableUndoButton = 'specs.programs.OOIEnableUndoButton',
  hideStartDate = 'specs.challenges.OOIHideStartDateSelection',
  enabledRenderSeoMetatagsList = 'specs.challenges.OOIRenderSEOMetaTagsList',
  enableProgramFieldSEOMetatags = 'specs.challenges.OOIRenderSeoMetatagsProgram',
  enableImageUrlFieldSEOMetatags = 'specs.challenges.OOISeoImageUrl',
  enableHideTeamEmail = 'specs.challenges.OOIHideSupportEmail',
  showSettingsPanel = 'specs.programs.ShowPaymentPageSettingsStudio',
  enableSeoMetatagsPayement = 'specs.programs.OOISeoMetatagsPaymentPage',
  enableSeoMetatagsThankYou = 'specs.programs.OOISeoMetatagsThankYouPage',
  enableParticipantPageInEditor = 'specs.programs.EnableParticipantPage',
  redirectToParticipantPage = 'spec.programs.RedirectToParticipantPage',
  enablePremium = 'specs.programs.OOIEnablePremium',
  enableParticipantPageMigrations = 'specs.programs.EnableParticipantPageMigrations',
  enableExtendedLimits = 'specs.programs.OOIExtendedLimits',
}

export type ExperimentsKeys = `${Challenges}`;
